<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    @save="onSaveForm()"
    @cancel="onCancel('SupportRequestsForCurrentUser')"
  >
    <template #waitst>
      <radio-buttons
        v-model="form.waitst"
        :buttons="radioButtons"
      ></radio-buttons>
    </template>
    <template #innerRequest>
      <radio-buttons
        v-model="form.innerRequest"
        :buttons="innerRequests"
      ></radio-buttons>
    </template>
  </sm-editable-item>
</template>

<script>
// mixins
import editableListItemCreate from '@/mixins/editableListItemCreate.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';
import RadioButtons from '@/components/client-sites/RadioButtons.vue';
export default {
  name: 'SupportRequestForCurrentUserCreate',

  components: {
    SmEditableItem,
    RadioButtons,
  },

  mixins: [editableListItemCreate],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'SupportRequestsForCurrentUser',
      pageHeader: 'Создание заявки',
      breadcrumbs: [
        {
          text: 'Заявки',
          route: { name: 'RequestsMain' },
        },
        {
          text: 'Мои заявки',
          route: { name: 'SupportRequestsForCurrentUser' },
        },
        {
          text: 'Создание заявки',
        },
      ],
      initialForm: {
        name: null,
        text: null,
        type: null,
        priority: null,
        cusID: null,
        aluserid: null,
        waitst: null,
        parentId: null,
        statusId: null,
        accID: null,
        innerRequest: null,
      },
      form: null,
      radioButtons: [
        { id: '0', name: 'Ставить в ожидание ответа от сотрудника' },
        { id: '1', name: 'Создать как подчиненную  для заявки' },
      ],
      innerRequests: [
        { id: '0', name: 'Заявка от клиента' },
        { id: '1', name: 'Внутренняя заявка' },
      ],
    };
  },

  computed: {
    ...mapState({
      priorities: (state) => state.common.priorities,
      types: (state) => state.common.types,
      customers: (state) => state.common.customers,
      gisTypes: (state) => state.common.gisTypes,
      initiationStatuses: (state) => state.common.initiationStatuses,
      accounts: (state) => state.common.accounts,
      performers: (state) => state.common.performers,
    }),

    fields() {
      return [
        {
          tab: 'Основные',
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Название',
            },
            {
              type: 'textarea',
              key: 'text',
              label: 'Текст заявки',
            },
            {
              type: 'select',
              key: 'type',
              label: 'Тип заявки',
              list: this.types.data || [],
            },
            {
              type: 'select',
              key: 'priority',
              label: 'Приоритет заявки',
              list: this.priorities.data || [],
            },
            {
              type: 'select',
              key: 'cusID',
              label: 'ИД клиента',
              list: this.customers.data,
            },
            {
              type: 'select',
              key: 'gtype',
              label: 'Тип заявки ГИС',
              list: this.gisTypes.data,
            },
            {
              type: 'select',
              key: 'aluserid',
              label:
                'ИД сотрудника для автоматического перевода после создания',
              list: this.performers.data,
            },
            {
              type: 'radio',
              key: 'waitst',
              label: 'Ставить в ожидание ответа от сотрудника',
            },
            {
              type: 'select',
              key: 'statusId',
              label: 'ИД статуса',
              list: this.initiationStatuses.data,
            },

            {
              type: 'select',
              key: 'parentId',
              label: 'ИД родительской заявки (для подчиненных заявок)',
              list: this.gisTypes.data,
            },

            {
              type: 'select',
              key: 'accID',
              label:
                'ИД аккаунта клиента, от которого необходимо создать заявку',
              list: this.accounts.data,
            },
            {
              type: 'checkbox',
              key: 'innerRequest',
              label: 'Заявка от клиента',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;

    const customers = this.getCommonList({ name: 'Customers' });
    const initiationStatuses = this.getCommonList({
      name: 'InitiationStatuses',
    });
    const gisTypes = this.getCommonList({ name: 'GisTypes' });
    const types = this.getCommonList({ name: 'types' });
    const priorities = this.getCommonList({ name: 'priorities' });
    const accounts = this.getCommonList({ name: 'accounts' });
    const performers = this.getCommonList({ name: 'performers' });

    Promise.all([
      customers,
      initiationStatuses,
      gisTypes,
      priorities,
      accounts,
      types,
      performers,
    ]).then(() => {
      this.isLoadingPage = false;
    });
  },

  methods: {
    ...mapActions({
      createItem: 'krisha/addRequest',
      getCommonList: 'common/getCommonList',
    }),
    onSaveForm() {
      this.form.waitst = Number(this.form.waitst);
      this.form.innerRequest = Number(this.form.innerRequest);

      this.onSave('addRequest');
    },
  },
};
</script>

<style lang="scss"></style>
